
$txt-color: #B9B9BF;
$white: #fff;
$color-hover: #00c3ff;
$color-st2 :#D6D0E8;
$font-main: 'Chakra Petch', sans-serif;
$font-main2: 'IBM Plex Mono', monospace;
$bg-main: url('/assets/images/background/bg4.jpg');
//$bg-main: #0d0c1a; // 141A31
$header-height: 100px;
//$bgr-body: url('../assets/images/background/bg3.jpg');
$bgr-body :#0d0c1a; // 0c1226 100f1d
$bg-2 :#1482c2; // Items Color etc
//$bg-3:url('/assets/images/background/bg4.jpg');
$bg-3 :#010005; // Header etc 141A31 010005
$bg-4 :#00aeff18; // Box BG etc 2288b71a
$border-width: 2px;
$corner-size: 3rem;
$font-w6:600;
$font-w7:700;
$color-main1 :#B9B9BF; 
$color-main2 :#565660; 
$color-3 :#0e2230; 
$w100:100%;
$mb-30: 30px;
$mg32: 32px;
$mb-40: 40px;
$ml-30: -30px;
$center: center;

$padding-right:18px;

$box-shadow: 0 0px 32px 0 #0077ff;

$boder-bt: 1px solid rgba(255,255,255,0.2);
$bg-gradient: linear-gradient(to bottom, #22adb7 10%, #E9E9E9 100%);
$bg-gradient2: linear-gradient(180deg, rgba(0, 0, 0, 0) 0.13%, #161616 100%);
$bg-gradient3: linear-gradient(90deg, rgba(0, 0, 0, 0) 0.13%, #161616 100%);
$res_1600: 1600px;
$res_1440: 1440px;
$res_1200: 1200px;
$res_991: 991px;
$res_767: 767px;
$res_600: 600px;
$res_500: 500px;
$res_400: 500px;

$mobile-width: 767px;
$tablet-width: 1024px;
$desktop-width: 1200px;
$desktop-1350: 1350px;
$desktop-1500: 1500px;
$desktop-1900 : 1900px;

